<template>
    <div>
      <section id="app" class="app bg1 wf-section">
      <div class="w-layout-blockcontainer main w-container"><img src="@/assets/images/heroes-dark.svg" loading="lazy" width="851.5" alt="" class="loader">
        <div class="div-block-5 div-block-6 div-block-7 div-block-8 div-block-9 div-block-10 div-block-11 div-block-12 div-block-13">
          <div><strong>Series:</strong> IV</div>
          <!-- <div><strong>Price: </strong>5000<strong> $WAR</strong> </div> -->
          <div><strong>Supply: </strong>???</div>
        </div>
        <!-- <LoginWith type="top" @login="() => handleLogin('LoadingView')" @appKeyFound="() => handleLogin('LoadingView')" @error="handleError"></LoginWith>
        <LoginWith @appKeyFound="() => handleLogin('LoadingView')" @error="handleError"></LoginWith> -->
        <!--NOT TO BE CONCERNED WITH, COMMENTED OUT BUT RETAINED AS IT WAS PREVIOUSLY USED-->
        <!-- <button @click="test_wallet_connect">TEST WALLET CONNECT</button>
          <button @click="test_wallet_connect_mint">TEST WALLET MINT</button> -->
        <!-- <PurchasePass @error="handleError"></PurchasePass> -->
        <!-- <LoginWith @login="() => handleLogin('LoadingView')" @appKeyFound="() => handleLogin('LoadingView')" @error="handleError"></LoginWith> -->
        <!-- <a href="https://awrd.gg/5886" target="_blank" class="anointed w-inline-block">
        <div class="claimbadge"><img src="@/assets/images/5886.png" loading="lazy" width="50" height="50" srcset="@/assets/images/5886.png 500w, @/assets/images/5886.png 512w" sizes="50px" alt="">
          <div class="text-block-4">Collect a Warrior Talisman for a half-priced mint</div>
        </div>
      </a> -->
      </div>
    </section>
</div>
  </template>
  
  <script>
  import { ref } from 'vue'
  import { useEligibilityStore } from '../store/eligibility'
  // import LoginWith from '../components/LoginWith.vue'
  // import PurchasePass from '../components/PurchasePass.vue'
  import { useContractStore } from '../store/contract'
//   import LoadingView from './LoadingView.vue'
//   import ConfirmationView from './ConfirmationView.vue'
  
  export default {
    name: 'index',
    components: {
      // LoginWith,
      // PurchasePass
    },
    setup(props, { emit }) {
      // const eligibilityStore = useEligibilityStore()
      const currentView = ref('LoginWith')
      const errorMessage = ref(null)
      const test_wallet_connect = () => {
        const contractStore = useContractStore()
        contractStore.initialize()
      }
      const test_wallet_connect_mint = async () => {
        const contractStore = useContractStore()
        await contractStore.mint(15)
      }
      const handleError = (error) => {
        errorMessage.value = error
        setTimeout(() => { errorMessage.value = null }, 5000) // Error message will disappear after 5 seconds
      }
  
      const handleLogin = async (view="LoadingView", skip=false) => {
        if(skip){
          const eligibilityStore = useEligibilityStore()
          eligibilityStore.skip = true
        }
        emit('next', view)
      }
      const checkDebug = () => {
        let url;
        url = window.location.href;
        if (url) {
            const searchParams = new URLSearchParams(new URL(url).search)
            let debug_logs = searchParams.get('debug_logs')
            
            if (debug_logs) {
              const eligibilityStore = useEligibilityStore()
              eligibilityStore.setPrintLogs(true)
            }
        }
      }
      checkDebug()
  
      return {
        currentView,
        handleLogin,
        handleError,
        errorMessage,
        test_wallet_connect,
        test_wallet_connect_mint
      }
    },
  }
  </script>
  
  <style>
  .buttons{
    height: 500px;
  }
  .error-message {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: red;
    color: white;
    padding: 1em;
    text-align: center;
  }
  .anointed{
    margin-top: 2rem;
  }
  </style>
  