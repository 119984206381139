<template>
    <section id="mint" class="app talisman-app wf-section bgMint">
      <div class="w-layout-blockcontainer main main-list w-container">
        <div class="mint-controller">
          <img src="@/assets/images/s3.gif" loading="lazy" width="500" height="500" srcset="@/assets/images/s3.gif 500w, @/assets/images/s3.gif 800w, @/assets/images/s3.gif 1024w" sizes="(max-width: 767px) 97vw, 500px" alt="" style="width:100%;height:auto;max-width:350px;">
          <div class="html-embed w-embed w-script">
            <div class="counter">
              <input v-model.number="value" id="demoInput" type="number" min="0" max="100" placeholder="0">
              <div class="buttons">
                <button class="controls" @click="decrement">-</button>
                <button class="controls" @click="increment">+</button>
              </div>
            </div>
          </div>
        </div>
        <div class="springboard">
          <div class="div-block-4">
            <p class="paragraph-3 price">{{ price }}<strong class="price-symbol">Ξ</strong></p>
          </div>
          <a id="mint" @click="selectMint"  v-bind:disabled="value <= 0" class="button-2 w-button"><strong>MINT NOW</strong></a>
          <div v-if="errorMsg" class="text-block-3">{{ errorMsg }}</div>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  import { ref, computed } from 'vue';
  import { useContractStore } from '../store/contract'
  
  export default {
    name: 'DirectToMint',
    setup(props, {emit}){
      const value = ref(0)
      const errorMsg = ref('')
      const contractStore = useContractStore()
  
      const price = computed(() => {
        const basePrice = 0.1 // assuming 0.1Ξ as base price
        let calculatedPrice = basePrice * value.value
        return Number(calculatedPrice.toFixed(2))
      });
  
      const selectMint = async () => {
        if(value.value < 1 || value.value > 100) {
          errorMsg.value = 'Value should be between 0 and 100'
        } else {
          errorMsg.value = ''
          let mintCount = value.value
          let result = await contractStore.mint(mintCount, price.value)
          if(result){
            emit('terminate', true)
          } else {
            console.debug('error', contractStore.mintError)
            if('insufficient funds for intrinsic transaction' in contractStore.mintError){
                errorMsg.value = "Insufficient funds in connected wallet to mint Warriors."
            } else {
                errorMsg.value = "There was an error processing your mint, please insure your wallet is connected and you have sufficient funds to cover the mint cost."//contractStore.mintError 
            }
            setTimeout(() => {
                errorMsg.value = '' 
            }, 10000)
          }
        }
      }
  
      const increment = () => {
        if(value.value < 100) {
          value.value += 1;
        } else {
          errorMsg.value = 'Maximum value reached';
        }
      }
  
      const decrement = () => {
        if(value.value > 0) {
          value.value -= 1;
        } else {
          errorMsg.value = 'Minimum value reached';
        }
      }
  
      return {
        selectMint,
        increment,
        decrement,
        value,
        errorMsg,
        price
      }
    }
  }
  </script>
  
  <style>
  /* ... keep existing styles ... */
  </style>
  