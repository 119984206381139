import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { setupStore } from './store'
import './assets/css/normalize.css'
import './assets/css/webflow.css'
import './assets/css/zerg.webflow.css'
import './assets/css/normalize_2.css'
import './assets/css/webflow_2.css'
import './assets/css/zerg.webflow_2.css'

// const INFURA_ID = process.env.VUE_APP_INFURA_PROJECT_ID
// // const ALCHEMY_KEY= process.env.ALCHEMY_KEY
// const INFURA_ID = 'cc5f5566002c4cd2898a662e9fa2c852'
// const ALCHEMY_KEY = '2yVft2tmwhovujGJpIWmTTWkJHLmw5hz'


const app = createApp(App)
app.use(router)
setupStore(app)
app.mount('#app')
