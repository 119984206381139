<template>
  <div>
    <NavBar></NavBar>
    <section id="thank-you" v-if="eligibility == 'wow'" class="app bg2 wf-section">
    <div class="w-layout-blockcontainer main w-container"><img src="@/assets/images/welcomewarrior.svg" loading="lazy" width="851.5" alt="" class="loader">
      <a href="https://reveal.zerg.studio" target="_blank" class="signin w-inline-block">
        <div class="sign-in-button">REVEAL WARRIORS</div>
      </a>
      <a href="https://opensea.io/collection/zergwarriors" class="link-2 black">View collection on Opensea</a>
      <!-- <a href="https://awrd.gg/6154" target="_blank" class="anointed w-inline-block">
        <div class="claimbadge"><img src="@/assets/images/anointed.png" loading="lazy" width="50" height="50" srcset="@/assets/images/anointed.png 500w, @/assets/images/anointed.png 512w" sizes="50px" alt="">
          <div class="text-block-4">Collect Anointed Talisman</div>
        </div>
      </a> -->
    </div>
  </section>
    <section v-else-if="eligibility == 'no_talismans'" id="thank-you" class="app bg2 wf-section">
      <div class="w-layout-blockcontainer main w-container"><img src="@/assets/images/notalismans.svg" loading="lazy" width="851.5" alt="" class="loader">
        <a href="https://discord.gg/zergstudio" target="_blank" class="signin w-inline-block">
          <div class="sign-in-button">ESCAPE TO THE HIDEOUT</div>
        </a>
        <a @click.prevent="skipToMint" class="pointer link-2 black">Skip to mint</a>
      </div>
    </section>
    <section v-else-if="eligibility == 'ineligible'" id="thank-you" class="app bg2 wf-section">
      <div class="w-layout-blockcontainer main w-container"><img src="@/assets/images/noallow.svg" loading="lazy" width="851.5" alt="" class="loader">
        <a href="https://discord.gg/zergstudio" target="_blank" class="signin w-inline-block">
          <div class="sign-in-button">ESCAPE TO THE HIDEOUT</div>
        </a>
        <a href="https://opensea.io/collection/zergwarriors" class="pointer link-2 black">View collection on Opensea</a>
      </div>
    </section>
    <section v-else-if="eligibility == 'sold_out'" id="thank-you" class="app bg2 wf-section">
      <div class="w-layout-blockcontainer main w-container"><img src="@/assets/images/WIN.svg" loading="lazy" width="851.5" alt="" class="loader">
        <a href="https://discord.gg/zergstudio" target="_blank" class="signin w-inline-block">
          <div class="sign-in-button">ESCAPE TO THE HIDEOUT</div>
        </a>
        <a href="https://opensea.io/collection/zergwarriors" class="pointer link-2 black">View collection on Opensea</a>
      </div>
    </section>
    <section v-else-if="eligibility == 'blocked'" id="thank-you" class="app bg2 wf-section">
      <div class="w-layout-blockcontainer main w-container"><img src="@/assets/images/blocked_wallet.svg" loading="lazy" width="851.5" alt="" class="loader">
        <a href="https://opensea.io/collection/zergwarriors" class="pointer link-2 black">View collection on Opensea</a>
      </div>
    </section>
    <section v-else id="thank-you" class="app bg2 wf-section">
      <div class="w-layout-blockcontainer main w-container"><img src="@/assets/images/ERROR_UNKNOWN.svg" loading="lazy" width="851.5" alt="" class="loader">
        <a href="https://discord.gg/zergstudio" target="_blank" class="signin w-inline-block">
          <div class="sign-in-button">GET HELP IN THE HIDEOUT</div>
        </a>
        <a @click.prevent="startOver" class="pointer link-2 black">Start over</a>
      </div>
    </section>
  </div>
  </template>
  
  <script>
  import NavBar from '../components/NavBar.vue'
  import { useEligibilityStore } from '../store/eligibility'
  import { onMounted, computed } from 'vue'
  import Cookies from 'js-cookie'
  import { useRouter } from 'vue-router'
  export default {
    name: 'Gated',
    components: {
      NavBar
    },
    setup() {
      const router = useRouter()
      const eligibiltyStore = useEligibilityStore()
      const eligibility = computed(() => eligibiltyStore.getEligibility)

      const skipToMint = () => {
        const eligibilityStore = useEligibilityStore()
        eligibilityStore.no_talisman = true
        router.push('/')
      }
      const startOver= () => {
        router.push('/')
      }
      onMounted(() => {
        if (eligibility.value === 'wow') {
          let warriorValue = Cookies.get('warrior') || 'none'
          // Push event to GTM data layer
          window.dataLayer = window.dataLayer || []
          window.dataLayer.push({
            'event': 'warrior_ref',
            'warriorValue': warriorValue
          })

          Cookies.remove('warrior')
        }
      })

      return{
        eligibility,
        skipToMint,
        startOver
      }
    }
  }
  </script>
  <style scoped>
  .anointed {
    margin-top: 2rem;
    position: absolute;
    bottom: 20%;
  }
  </style>