<template>
    <section id="state2" class="app talisman-app wf-section bgMint">
      <div class="w-layout-blockcontainer main main-list w-container mint-confirmation">
        <ul role="list" class="talismans w-list-unstyled">
          <li class="talisman" v-for="(detail, index) in details" :key="index">
            <a :href="detail.link" target="_blank" @click.prevent="badgeClicked(detail)" class="link-block-2 w-inline-block">
              <img :src="detail.image" loading="lazy" width="100" height="100" alt="" class="talisman-image">
              <div class="div-block-3">
                <h3 class="heading-3">{{ detail.name }}</h3>
                <p class="paragraph-2">{{ detail.description }}</p>
              </div>
              <p class="talisman-count">{{ detail.count }}</p>
            </a>
          </li>
        </ul>
        <div class="springboard">
          <ul role="list" class="elementals w-list-unstyled">
            <li class="elemental" v-for="elemental in elementals" :key="elemental.id">
              <a :href="elemental.link" target="_blank" class="w-inline-block"><img :src="elemental.image" loading="lazy" width="75" height="75" alt="" :class="{ 'elemental-image': true, 'not-earned': !hasTokenId(elemental.id) }"></a>
            </li>
          </ul>
          <button @click.stop="confirmClicked" class="button-2 w-button" :class="{'button-enabled': !(selectedFreeCount == 0 && selectedHalfPricedCount == 0), 'button-disabled': selectedFreeCount == 0 && selectedHalfPricedCount == 0}" :disabled="selectedFreeCount == 0 && selectedHalfPricedCount == 0"><strong>MINT</strong></button>
          <div class="div-block-4">
            <p class="paragraph-3" v-if="selectedFreeCount || selectedHalfPricedCount">
                {{ selectedFreeCount}} Free {{ selectedHalfPricedCount}} Half-priced
            </p>
            <p class="paragraph-3" v-else>
                Select badges you wish to redeem to mint
            </p>
          </div>

        </div>
      </div>
    </section>
  </template>
  
  <script>
  import { useEligibilityStore } from '../store/eligibility';
  // import { computed, watch, ref } from 'vue';
  import { computed, ref } from 'vue';
  
  export default {
    name: 'ConfirmationView',
    setup(props, {emit}){
      const eligibilityStore = useEligibilityStore()
      const getSelectedCount = (selectedArray) => {
        return selectedArray.reduce((total, item) => total + item.selected, 0)
      }
      let selectedFreeCount = ref(getSelectedCount(eligibilityStore.getSelectedFree))
      let selectedHalfPricedCount = ref(getSelectedCount(eligibilityStore.getSelectedHalf))
      
      const badgeClicked = (badgeDetail) => {
        if (badgeDetail.count > 0) {
            let badgeDetailCopy = { ...badgeDetail };

            badgeDetailCopy.count--;

            let detailIndex = eligibilityStore.detailsGetter.findIndex(detail => detail.name === badgeDetail.name);
            if (detailIndex !== -1) {
                eligibilityStore.detailsGetter[detailIndex] = badgeDetailCopy;
            }

            eligibilityStore.setBadgeDetail(badgeDetailCopy);

            if (badgeDetail.free){
              selectedFreeCount.value++
            } else {
              selectedHalfPricedCount.value++
            }
        }
    }

    const isAnyBadgeSelected = computed(() => {
        return eligibilityStore.hasSelectedBadges;
    });

      const confirmClicked = () => {
        emit('next', 'SelectionView')
      }
  
      const hasTokenId = (id) => {
        return eligibilityStore.hasTokenId(id);
      }
      
      const free_check = ref(eligibilityStore.freeCountGetter == 0)
      const half_check = ref(eligibilityStore.halfPricedCountGetter == 0)
      if(free_check.value && half_check.value){
        eligibilityStore.setEligible('no_talismans')
        emit('terminate', true)

      }
  
      const elementals = [
        { id: 5925, link: 'https://awrd.gg/5925', image: '/images/5925.png' },
        { id: 5926, link: 'https://awrd.gg/5926', image: '/images/5926.png' },
        { id: 5927, link: 'https://awrd.gg/5927', image: '/images/5927.png' },
        { id: 5928, link: 'https://awrd.gg/5928', image: '/images/5928.png' },
        { id: 5929, link: 'https://awrd.gg/5929', image: '/images/5929.png' }
      ]
      return {
        confirmClicked,
        details: eligibilityStore.detailsGetter,
        elementals,
        freeCount: eligibilityStore.freeCountGetter,
        halfPricedCount: eligibilityStore.halfPricedCountGetter,
        hasTokenId,
        badgeClicked,
        isAnyBadgeSelected,
        selectedFreeCount,
        selectedHalfPricedCount
      }
    }
  }
  </script>
  <style scoped>
  .main.main-list.mint-confirmation{
    height: 100vh;
  }
  .link-block-2{
    gap: .5rem;
    padding-right: 1rem;
  }
  .button-2.w-button:disabled {
    background-color: #606060 !important;
    color: #0f0f0f !important;
  }
  </style>