import { defineStore } from "pinia";

export const useUserStore = defineStore({
    id: 'user',
    state: () => ({
        appKey: null,
        apiKey: null
    }),
    getters: {
        getAppKey: (state) =>{
            return state.appKey
        },
        getAPIKey: (state) => {
            return state.apiKey
        }
    },
    actions: {
        setUserData(appKey, apiKey) {
            this.appKey = appKey
            this.apiKey = apiKey
        }
    }
})