<template>
    <div>
        <NavBar></NavBar>
      <component 
        :is="currentView" 
        @next="updateView" 
        @terminate="loadSuccess" 
        @error="handleError">
      </component>
      <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
      <Footer></Footer>
    </div>
  </template>
  
  <script>
  import { ref } from 'vue'
  import { useRouter } from 'vue-router'
  import { useEligibilityStore } from '../store/eligibility'
  import LoginView from '../components/LoginView.vue'
  import LoadingView from '../components/LoadingView.vue'
  import ConfirmationView from '../components/ConfirmationView.vue'
  import SelectionView from '../components/SelectionView.vue'
  import DirectToMint from '../components/DirectToMint.vue'
  import NavBar from '../components/NavBar.vue'
  import Footer from '../components/Footer.vue'
  
  export default {
    name: 'index',
    components: {
      LoginView: LoginView,
      LoadingView,
      ConfirmationView,
      SelectionView,
      NavBar,
      DirectToMint,
      Footer
    },
    setup() {
      const router = useRouter()
      const currentView = ref('LoginView')
      const errorMessage = ref(null)
      const eligibilityStore = useEligibilityStore()
      
      const no_talismans = eligibilityStore.no_talisman

      if(no_talismans){
        currentView.value = 'DirectToMint'
      }
      const loadSuccess = () => {
        router.push('/thank-you')
      }
  
      const updateView = (newView) => {
        currentView.value = newView
      }
  
      const handleError = (error) => {
        errorMessage.value = error
        setTimeout(() => { errorMessage.value = null }, 5000) // Error message will disappear after 5 seconds
      }
  
      return {
        currentView,
        updateView,
        loadSuccess,
        handleError,
        errorMessage,
      }
    },
  }
  </script>
  
  <style>
  .error-message {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: red;
    color: white;
    padding: 1em;
    text-align: center;
  }
  </style>
  