<template>
  <div>
    <section v-if="!eligibilityStore.skip" id="checking" class="app app-black talisman-loader wf-section">
      <div class="w-layout-blockcontainer main w-container"><img src="@/assets/images/talismans.gif" loading="lazy" width="125" height="125" alt="" class="loader talisman">
        <a id="signIn" href="https://awrd.gg" target="_blank" class="signin checking w-inline-block">
          <div class="sign-in-button loading">CHECKING BALANCE</div>
        </a>
      </div>
    </section>
    <section v-else id="loading" class="app app-black wf-section">
      <div class="w-layout-blockcontainer main w-container"><img src="@/assets/images/loader2.gif" loading="lazy" width="125" height="125" alt="" class="loader cube">
        <a id="signIn" href="https://awrd.gg" target="_blank" class="signin checking w-inline-block">
          <div class="sign-in-button loading">Loading</div>
        </a>
      </div>
    </section>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useEligibilityStore } from '../store/eligibility';
import { useRouter } from 'vue-router'

export default {
  name: 'LoadingView',
  setup(props, { emit }){
    const router = useRouter()
    const checking = ref(true);
    const eligibilityStore = useEligibilityStore()
    const handleDetails = async () => {
      const isEligible = await eligibilityStore.checkEligibilitySimulate()
      if(isEligible){
        await eligibilityStore.constructDetails()
        await eligibilityStore.loadContractDetails()
        if(eligibilityStore.print_logs){
          router.push('/support')
        }
        // setTimeout(() => {
          // checking.value = false;
        if(isEligible == 'skip'){
          setTimeout(() => {
            emit('next', 'SelectionView')
          }, 5000);
        } else {
          setTimeout(() => {
            emit('next', 'SelectionView')
          }, 2000);
        }

        // }, 5000);
      } else {
        router.push('/thank-you')
      }
    }

    handleDetails()

    return {
      checking,
      eligibilityStore
    }
  }
}
</script>
