<template>
  <div id="app">
    <GTM></GTM>
    <router-view />
  </div>
</template>

<script>
import GTM from './components/GTM'
import { onMounted } from 'vue';
export default {
  name: 'App',
  components: {
    GTM
  },
  setup(){
    onMounted(()=> {
      window.document.title = 'WARRIORS BY ZERG - Minting now!'
      window.document.description = '10k AI Warriors battling on the Ethereum Network. An experience by ZERG STUDIO.'
    })
  }
}
</script>

<style>
.html{
  background-color: #0f0f0f;
}
</style>
