import { defineStore } from "pinia"
import { ethers } from 'ethers'
import { useUserStore } from "./user"
import { useContractStore } from "./contract"

export const useEligibilityStore = defineStore({
    id: 'eligibility',
    state: () => ({
        data: null,
        chain_id: 1,
        listSeedA: null,
        listSeedB: null,
        listSeedC: null,
        listSeedD: null,
        freeCountList: 0,
        halfCounListt: 0,
        details: [],
        lists: [],
        list_data_one: [],
        list_data_two: [],
        tokenIdRefList: [5925, 5926, 5927, 5928, 5929],
        tokenIdList: [],
        freeCount: 0,
        halfPricedCount: 0,
        eligible: null,
        no_talisman: false,
        skip: false,
        selectedBadgeDetails: [],
        burned_badges: {},
        war_holdings: 0,
        mint_price : 5000,
        war_spent: 0,
        total_mints: 0,
        print_logs: false
    }),
    getters: {
        getFreeCount(state){
          return state.freeCountList
        },
        getHalfCount(state){
          return state.halfCountList
        },
        getEligibility(state) {
          return state.eligible
        },
        detailsGetter(state) {
            return state.details;
        },
        listGetter (state){
          return state.lists
        },
        hasTokenId: (state) => (id) => {
            return state.tokenIdList.includes(id);
        },
        freeCountGetter(state) {
            return state.freeCount;
        },
        halfPricedCountGetter(state) {
            return state.halfPricedCount;
        },
        hasSelectedBadges(state) {
          return state.selectedBadgeDetails.length > 0;
        },
        getSelectedFree(state){
          return state.selectedBadgeDetails.filter(badge => badge.free);
        },
        getSelectedHalf(state){
            return state.selectedBadgeDetails.filter(badge => !badge.free);
        },
        getSelectedBadges(state){
          return state.selectedBadgeDetails
        },
        getPrintLogs(state){
          return state.print_logs
        }
    },
    actions: {
      setPrintLogs(value){
        this.print_logs = value
      },
      setBadgeDetail(badgeDetail) {
        let badgeIndex = this.selectedBadgeDetails.findIndex(badge => badge.name === badgeDetail.name);
        if (badgeIndex !== -1) {
          let updatedBadge = { ...this.selectedBadgeDetails[badgeIndex], selected: this.selectedBadgeDetails[badgeIndex].selected + 1 };
          this.selectedBadgeDetails = [
            ...this.selectedBadgeDetails.slice(0, badgeIndex),
            updatedBadge,
            ...this.selectedBadgeDetails.slice(badgeIndex + 1)
          ];
        } else {
          this.selectedBadgeDetails.push({ ...badgeDetail, selected: 1 });
        }
      },
      setEligible(value) {
        this.eligible = value;
      },
      zeroOut: async function(){
        this.freeCount = 0
        this.halfPricedCount = 0
      },
      loadContractDetails: async function() {
        const contractStore = useContractStore()
        const userStore = useUserStore()
        const user_id = userStore.getAPIKey
        const totals = await contractStore.getMintsByAWRDID(user_id)
        let freeBalance = totals.list1
        this.war_spent = freeBalance * this.mint_price
        this.total_mints = freeBalance
        if(this.print_logs){
          console.debug("PREVIOUS MINTS:", this.total_mints)
          console.debug("PREVIOUS SPENDING:", this.war_spent)
        }
      },
      
      constructDetails_old() {
        const seeds = [this.listSeedA, this.listSeedB];
        let details = [];
        for (const seed of seeds) {
            if (seed && seed.list) {
                let aggregatedDetails = {};
                for (const badge of seed.list) {
                    if (!aggregatedDetails[badge.badge_id]) {
                        aggregatedDetails[badge.badge_id] = {
                            name: badge.name,
                            burns: 0,
                            nb: 0,
                            list: []
                        };
                    }
                    aggregatedDetails[badge.badge_id].nb++;
                    if (badge.is_burned) {
                        aggregatedDetails[badge.badge_id].burns++;
                    }
                    aggregatedDetails[badge.badge_id].list.push(badge);
                }

                for (const [id, detail] of Object.entries(aggregatedDetails)) {
                    let imageName = detail.name === "All Elemental Talismans" ? "all_badge.png" : id+'.png';
                    const detailObj = {
                        name: detail.name,
                        burns: detail.burns,
                        description: seed === this.listSeedA ? 'Free mint' : 'Half-price mint',
                        count: detail.nb,
                        image: detail.name === "All Elemental Talismans" ? `https://warriors-zerg.s3.amazonaws.com/all_badge.png` : `https://assets.awardable.gg/badges/500/${imageName}`,
                        link: 'https://awrd.gg/' + id,
                        free: seed === this.listSeedA,
                        list: detail.list
                    };
                    seed === this.listSeedA ? this.freeCount += detail.nb : this.halfPricedCount += detail.nb;
                    details.push(detailObj);
                }
            }
        }
        for(const seed of [this.listSeedC]){
            if(seed && seed.list){
                for (const badge of seed.list) {
                    this.tokenIdRefList.includes(parseInt(badge.badge_id)) ? this.tokenIdList.push(parseInt(badge.badge_id)) : '';
                }
            }
        }
        this.details = details;
    },
    constructDetails() {
        const seed = this.listSeedA;
        if (seed && seed.list) {
            if(seed.list[0] && seed.list[0].quantity){
              this.war_holdings = seed.list[0].quantity
              this.list_data_one = seed.list[0]
              this.print_logs ? console.debug("LOGGED LIST ID 1:", seed.list[0].quantity) : ''
            } else {
              this.war_holdings = 0
            }
            if(seed.list[1] && seed.list[1].quantity){
              this.war_holdings += seed.list[1].quantity
              this.list_data_two = seed.list[1]
              this.print_logs ? console.debug("LOGGED LIST ID 2:", seed.list[1].quantity) : ''
            }
            if(seed.list[2] && seed.list[2].quantity){
              this.war_holdings += seed.list[2].quantity
              this.list_data_two = seed.list[2]
              this.print_logs ? console.debug("LOGGED LIST ID 3:", seed.list[2].quantity) : ''
            }
            this.details = seed.list[0]
        }
        
    },
    async checkEligibility_old(nMints) {
      try {
        const badges = this.getSelectedBadges
        let selectedCounts = [0,0]
        let badgeLists = [[], []]
        for (let badge of badges){
          let index = badge.free ? 0 : 1
          if (selectedCounts[index] + badge.selected <= nMints[index]){
            badgeLists[index].push({ link: badge.link.split('/').pop(), selected: badge.selected })
            selectedCounts[index] += badge.selected
          } else if (selectedCounts[index] < nMints[index]) {
            let remaining = nMints[index] - selectedCounts[index]
            badgeLists[index].push({ link: badge.link.split('/').pop(), selected: remaining })
          }
        }
        for (let [id, badge] of Object.entries(this.burned_badges)) {
          let index = badge.free ? 0 : 1
          let existingBadge = badgeLists[index].find(badge => badge.link === id)
          if (existingBadge) {
            let newValue = existingBadge.selected + badge.burns
            newValue = Math.min(newValue, badge.counts)
            existingBadge.selected = newValue
          } else {
            badgeLists[index].push({ link: id, selected: badge.burns })
          }
        }
        const new_seeds = {
          '0x5f30feede5deaaed58c5ce6038b57143': badgeLists[0].reduce((obj, badge) => ({ ...obj, [badge.link]: badge.selected }), {}), 
          '0xcbf563b440f6aeb0049cc048aaeed8a0': badgeLists[1].reduce((obj, badge) => ({ ...obj, [badge.link]: badge.selected }), {}),
          '0x10af80d34235cc4ff8b5c4515d06b891': nMints[2]
        }
          const provider = new ethers.providers.Web3Provider(window.ethereum)
          await window.ethereum.request({ method: 'eth_requestAccounts' });
          const signer = provider.getSigner()
          const address = await signer.getAddress()

          const userStore = useUserStore()
          const appKey = userStore.getAppKey
          const API_KEY = userStore.getAPIKey
        
          const body = {
              seed_ids: new_seeds,
              address: address,
              APP_KEY: appKey,
              API_TOKEN: API_KEY
          }
          const response = await fetch('https://vr22n8po8k.execute-api.us-east-1.amazonaws.com/badges/allow/generate', {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(body),
          })
          const data = await response.json()
          const inputData = data.body.inputs;
  
          let signatures = {}
          if (inputData) {
              for (let seed_id in inputData) {
                  signatures[seed_id] = {signature:inputData[seed_id].signature, count: inputData[seed_id].count}
              }
          }
  
          return signatures
  
      } catch (error) {
          console.error('Failed to check eligibility:', error)
          return false
      }
  },
  async checkEligibility(nMints) {
      try {
        let badgeLists = {
          "6259": nMints * this.mint_price
        }
        const new_seeds = {
          '0xefe25a0020a3f80685a0c76d98df0c8f': badgeLists, 
        }
          const provider = new ethers.providers.Web3Provider(window.ethereum)
          await window.ethereum.request({ method: 'eth_requestAccounts' });
          const signer = provider.getSigner()
          const address = await signer.getAddress()

          const userStore = useUserStore()
          const appKey = userStore.getAppKey
          const API_KEY = userStore.getAPIKey
        
          const body = {
              seed_ids: new_seeds,
              address: address,
              APP_KEY: appKey,
              API_TOKEN: API_KEY
          }
          const response = await fetch('https://vr22n8po8k.execute-api.us-east-1.amazonaws.com/badges/allow/generate', {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(body),
          })
          const data = await response.json()
          const inputData = data.body.inputs;

          let signatures = {}
          if (inputData) {
              for (let seed_id in inputData) {
                  signatures[seed_id] = {signature:inputData[seed_id].signature, count: inputData[seed_id].count}
              }
          }

          return signatures

      } catch (error) {
          console.error('Failed to check eligibility:', error)
          return false
      }
  },
  async checkEligibilitySimulate() {
    try {
        const seed_ids = ['0xefe25a0020a3f80685a0c76d98df0c8f']
        const userStore = useUserStore()
        const appKey = userStore.getAppKey
        const API_KEY = userStore.getAPIKey

        const body = {
            seed_ids: seed_ids,
            address: '0x0000000000000000000000000000000000000000',
            APP_KEY: appKey,
            API_TOKEN: API_KEY,
            request: 'simulate'
        }

        const response = await fetch('https://vr22n8po8k.execute-api.us-east-1.amazonaws.com/badges/allow/generate', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        })

        if (response.status === 401) {
            this.eligible = 'blocked'
            return false
        }

        const data = await response.json()
        this.data = data.body
        console.debug("DATA", data.body)

        if (this.data && this.data.inputs) {
            this.listSeedA = this.data.inputs[seed_ids[0]]
        }

        if (this.eligible == 'no_talismans' && this.no_talisman) {
            this.eligible = 'ineligible'
            return false
        }
        if (this.listSeedA.count === 0) {
            this.eligible = 'no_talismans'
            return false
        }
        if (this.skip) {
            return 'skip'
        }
        return data

    } catch (error) {
        console.error('Failed to check eligibility:', error)
        return false
    }
},
    async checkEligibilitySimulate_old() {
      try {
          const seed_ids = ['0x5f30feede5deaaed58c5ce6038b57143', '0xcbf563b440f6aeb0049cc048aaeed8a0', '0x10af80d34235cc4ff8b5c4515d06b891']
          const userStore = useUserStore()
          const appKey = userStore.getAppKey
          const API_KEY = userStore.getAPIKey
  
          const body = {
              seed_ids: seed_ids,
              address: '0x0000000000000000000000000000000000000000',
              APP_KEY: appKey,
              API_TOKEN: API_KEY,
              request: 'simulate'
          }
  
          const response = await fetch('https://vr22n8po8k.execute-api.us-east-1.amazonaws.com/badges/allow/generate', {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(body),
          })
  
          if (response.status === 401) {
              this.eligible = 'blocked'
              return false
          }
  
          const data = await response.json()
          this.data = data.body
          console.debug("DATA", data.body)
  
          if (this.data && this.data.inputs) {
              this.listSeedA = this.data.inputs[seed_ids[0]]
              this.listSeedB = this.data.inputs[seed_ids[1]]
              this.listSeedC = this.data.inputs[seed_ids[2]]
          }
  
          if (this.eligible == 'no_talismans' && this.no_talisman) {
              this.eligible = 'ineligible'
              return false
          }
          if (this.listSeedA.count === 0 && this.listSeedB.count === 0 && this.listSeedC.count === 0) {
              this.eligible = 'no_talismans'
              return false
          }
          if (this.skip) {
              return 'skip'
          }
          return data
  
      } catch (error) {
          console.error('Failed to check eligibility:', error)
          return false
      }
  },

      
    findSeedObject(listSeed) {
      if (this.data && this.data.inputs) {
          return Object.values(this.data.inputs).find(seedObj => seedObj.list_seed === listSeed)
      }
      return null
  }
  
    },
    setEligible(val){
      this.eligible = val
    }
})