<template>
    <div>
      <h1>Public Content</h1>
      <p>All users have access to this content, regardless of their login status.</p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'Public',
  }
  </script>
  