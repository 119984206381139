import { defineStore } from "pinia";
import { ethers } from 'ethers';
import { useEligibilityStore } from './eligibility';
import { useUserStore } from './user'
import { addresses as mint_addresses, abi as mint_abi } from "@/assets/contracts/warriors.json";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";

const INFURA_ID = process.env.VUE_APP_INFURA_PROJECT_ID

// const web3Modal = new Web3Modal({
//   network: "mainnet", // optional
//   cacheProvider: true, // optional
//   providerOptions: {
//     walletconnect: {
//       package: WalletConnectProvider,
//       options: {
//         infuraId: INFURA_ID,
//       },
//     },
//     // Add other providers here
//   },
// });

export const useContractStore = defineStore({
    id: 'contract',
    state: () => ({
        abi: mint_abi,  
        contract: null,
        chainId: 1, 
        seeds: {
            free: '0x5f30feede5deaaed58c5ce6038b57143',
            half: '0xcbf563b440f6aeb0049cc048aaeed8a0',
            al: '0x10af80d34235cc4ff8b5c4515d06b891',
            post_mint: '',
            skip_confirm: false
        },
        contractAddress: null,
        mintError: null,
    }),
    actions: {
        initialize: async function () {
            try {
                const providerOptions = {
                    walletconnect: {
                    package: WalletConnectProvider,
                    options: {
                        infuraId: INFURA_ID,
                    },
                },
                };
        
                this.web3Modal = new Web3Modal({
                    network: "mainnet", 
                    cacheProvider: true, 
                    providerOptions, 
                    disableInjectedProvider: false,
                });
        
                this.provider = await this.web3Modal.connect();
        
                const network = await this.provider.getNetwork();
                this.chainId = network.chainId;
        
                const signer = this.provider.getSigner();
                this.connectedAddress = await signer.getAddress();
        
            } catch (error) {
                console.debug('Web3 initialization error:', error);
            }
        },
        mint: async function(nMints) {
            try {
                if(!this.provider) {
                    await this.initialize();
                }
                if(!this.contractAddress){
                    this.contractAddress = mint_addresses[this.chainId]
                }
                const provider = new ethers.providers.Web3Provider(this.provider) // Use the provider from WalletConnect
                const signer = provider.getSigner()
                const address = await signer.getAddress()
                await this.checkChainId(provider)
                const eligibilityStore = useEligibilityStore();
        
                const pricePerBadge = ethers.utils.parseEther("0.1"); // adjust this to your price per badge in ETH
                let totalPrice = pricePerBadge.mul(nMints);
                
                const contractWithSigner = new ethers.Contract(this.contractAddress, this.abi, signer);
        
                const tx = await contractWithSigner.mint(address, nMints, { value: totalPrice });
                eligibilityStore.setEligible('wow');
                const receipt = await tx.wait();
        
                return receipt;
            } catch (error) {
                console.debug("Mint failed:", error);
                this.mintError = error
                return false
            }
        },
        
        checkChainId: async function(provider, ){
            const currentChainId = await provider.send('eth_chainId')
          if (parseInt(currentChainId, 16) !== this.chainId) {
              try {
                  // Attempt to switch to the correct chain
                  await provider.send('wallet_switchEthereumChain', [{ chainId: `0x${this.chainId.toString(16)}` }])
              } catch (switchError) {
                  // This error code indicates that the chain does not exist on the wallet
                  if (switchError.code === 4902) {
                      throw new Error('The requested chain does not exist on the wallet. Please add it manually.')
                  }
                  throw switchError
              }
          }
        },
        multiClaim: async function(nMints) {
            try {
                const userStore = useUserStore()
                if(!this.provider) {
                    await this.initialize();
                }
                const provider = new ethers.providers.Web3Provider(this.provider) // Use the provider from WalletConnect
                const signer = provider.getSigner()
                const address = await signer.getAddress()
                await this.checkChainId(provider)
                
                const priceMap = {
                    '0xefe25a0020a3f80685a0c76d98df0c8f': ethers.utils.parseEther("0.00"),
                };
                const debug = false; 
                const eligibilityStore = useEligibilityStore();
                if(!this.contractAddress){
                    this.contractAddress = mint_addresses[this.chainId]
                }
                
                const seeds = ['0xefe25a0020a3f80685a0c76d98df0c8f'];
                //* Probably will never need this, however can't hurt to have it at the ready
                const awrdID = userStore.getAPIKey
                const vals = await this.getMintsByAWRDID(awrdID)
                const adj_nMints = nMints[0] + vals.list1
                const signatures = await eligibilityStore.checkEligibility(adj_nMints);
                const seedObjects = seeds.map(seed => ({
                    awrd_id: awrdID,
                    count: signatures[seed].count,
                    list_seed: seed,
                    address: address, //eligibilityStore.findSeedObject(seed).address,
                    signature: signatures[seed].signature
                }));
                const signatureDataArray = seedObjects.map((obj, index) => ({
                    awrdId: obj.awrd_id,
                    nMints: nMints[index],
                    nCounts: obj.count,
                    seedId: obj.list_seed,
                    minter: obj.address,
                    signature: obj.signature
                })).filter(item => item.signature !== null && item.signature !== undefined && item.nMints > 0);                
                const contractWithSigner = new ethers.Contract(this.contractAddress, this.abi, signer);
        
                if(debug){
                    priceMap[seeds[0]] = ethers.utils.parseEther("0.00");
                }
                
                let totalPrice = ethers.BigNumber.from("0");
                for (let index = 0; index < signatureDataArray.length; index++) {
                    totalPrice = totalPrice.add(priceMap[signatureDataArray[index].seedId].mul(signatureDataArray[index].nMints));
                }
                // Use destructuring to get awrdId and minter from the first object in the array
                const {awrdId, minter} = signatureDataArray[0];
                const tx = await contractWithSigner.multiClaim(awrdId, minter, signatureDataArray, { value: totalPrice });
                const receipt = await tx.wait();
                eligibilityStore.setEligible('wow');
                return receipt;
            } catch (error) {
                console.debug(error)
                this.mintError = error
                return false
            }
        },
        
        async getTotalSupply(){
            try {
                if(!this.contractAddress){
                    this.contractAddress = mint_addresses[this.chainId]
                }
                const provider = new ethers.providers.Web3Provider(window.ethereum);
                const signer = provider.getSigner();
                const contractWithSigner = new ethers.Contract(this.contractAddress, this.abi, signer);
                return await contractWithSigner.totalSupply()
            } catch (error) {
                console.debug("Error getting total supply:", error)
            }
        },
        async getOwnerOf(tokenId){
            try {
                if(!this.contractAddress){
                    this.contractAddress = mint_addresses[this.chainId]
                }
                const provider = new ethers.providers.Web3Provider(window.ethereum);
                const signer = provider.getSigner();
                const contractWithSigner = new ethers.Contract(this.contractAddress, this.abi, signer);
                return await contractWithSigner.ownerOf(tokenId)
            } catch (error) {
                console.debug("Error getting owner:", error)
            }
        },
        async getMintsByAWRDID_old(awrdID) {
            try {
                if (!this.contractAddress) {
                    this.contractAddress = mint_addresses[this.chainId];
                }
                const user_id = Number(awrdID)
        
                const MAINNET_RPC_URL = this.chainId == 1 ? 'https://mainnet.infura.io/v3/' + INFURA_ID : 'https://goerli.infura.io/v3/' + INFURA_ID
                const provider = new ethers.providers.JsonRpcProvider(MAINNET_RPC_URL);

        
                const contractWithSigner = new ethers.Contract(this.contractAddress, this.abi, provider);
                const mintsList1 = await contractWithSigner.mintListFreeMint(user_id);
                const mintsList2 = await contractWithSigner.mintListHalfMint(user_id);
                return {
                    list1: mintsList1.toNumber(),
                    list2: mintsList2.toNumber()
                };
            } catch (error) {
                console.debug('Error getting mint quantities:', error);
            }
        },
        async getMintsByAWRDID(awrdID) {
            try {
                if (!this.contractAddress) {
                    this.contractAddress = mint_addresses[this.chainId];
                }
                const user_id = Number(awrdID)
        
                const MAINNET_RPC_URL = this.chainId == 1 ? 'https://mainnet.infura.io/v3/' + INFURA_ID : 'https://goerli.infura.io/v3/' + INFURA_ID
                const provider = new ethers.providers.JsonRpcProvider(MAINNET_RPC_URL);

        
                const contractWithSigner = new ethers.Contract(this.contractAddress, this.abi, provider);
                const mintsList1 = await contractWithSigner.mintListFreeMint(user_id);
                return {
                    list1: mintsList1.toNumber(),
                };
            } catch (error) {
                console.debug('Error getting mint quantities:', error);
            }
        }
    }
});
