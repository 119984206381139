<template>
    <div>
      <NavBar></NavBar>
      <section id="thank-you" class="app bg2 wf-section">
      <div class="w-layout-blockcontainer main w-container">
        <a @click="copy_logs" target="_blank" class="signin w-inline-block">
          <div class="sign-in-button">COPY LOGS</div>
        </a>
        <p v-if="message" class="link-2 black">{{ message }}</p>
        <!-- <a href="https://awrd.gg/6154" target="_blank" class="anointed w-inline-block">
          <div class="claimbadge"><img src="@/assets/images/anointed.png" loading="lazy" width="50" height="50" srcset="@/assets/images/anointed.png 500w, @/assets/images/anointed.png 512w" sizes="50px" alt="">
            <div class="text-block-4">Collect Anointed Talisman</div>
          </div>
        </a> -->
      </div>
    </section>
    </div>
    </template>
    
    <script>
    import NavBar from '../components/NavBar.vue'
    import { useEligibilityStore } from '../store/eligibility'
    import { computed, ref } from 'vue'
    import { useRouter } from 'vue-router'
    export default {
      name: 'Gated',
      components: {
        NavBar
      },
      setup() {
        const router = useRouter()
        const eligibiltyStore = useEligibilityStore()
        const eligibility = computed(() => eligibiltyStore.getEligibility)
        const message = ref("")
        const skipToMint = () => {
          const eligibilityStore = useEligibilityStore()
          eligibilityStore.no_talisman = true
          router.push('/')
        }
        const startOver= () => {
          router.push('/')
        }
        
        const copy_logs = () => {
            const list_one = eligibiltyStore.list_data_one
            const list_two = eligibiltyStore.list_data_two
            const war_spent = eligibiltyStore.war_spent
            const total_mints = eligibiltyStore.total_mints
            const war_holdings = eligibiltyStore.war_holdings
            const { badge_id, burned_time, is_burned, is_point, name, quantity, symbol } = eligibiltyStore.details
            const data = eligibiltyStore.data
            const clipboardData = `
                ID: ${badge_id}
                BURNED TIME: ${burned_time}
                BURNED: ${is_burned}
                IS POINT: ${is_point}
                NAME: ${name}
                QUANTITY: ${quantity}
                SYMBOL: ${symbol}
                War Spent: ${war_spent}
                Total Mints: ${total_mints}
                War Holdings: ${war_holdings}
                Data: ${JSON.stringify(data)}
                LIST ONE: ${list_one}
                LIST TWO: ${list_two}
                    `
            navigator.clipboard.writeText(clipboardData).then(() => {
                message.value= "Logs copied!"
            }).catch(() => {
                message.value="Failed to copy logs."
            });
        }
  
        return{
          eligibility,
          skipToMint,
          startOver,
          copy_logs,
          message
        }
      }
    }
    </script>
    <style scoped>
    .anointed {
      margin-top: 2rem;
      position: absolute;
      bottom: 20%;
    }
    </style>
    