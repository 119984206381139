<template>
    <div data-animation="default" data-collapse="medium" data-duration="400" data-easing="ease" data-easing2="ease" role="banner" class="navbar11_component background-color-black w-nav">
    <div class="navbar11_container">
      <a href="https://zerg.studio/" aria-current="page" class="navbar11_logo-link w-nav-brand w--current"><img src="@/assets/images/ZERG.svg" loading="lazy" alt="" class="navbar11_logo"></a>
      <nav role="navigation" class="nav-menu w-nav-menu">
        <nav role="navigation" class="navbar11_menu is-page-height-tablet w-nav-menu">
          <!-- <a href="index.html" aria-current="page" class="navbar11_link w-nav-link w--current">Home</a> -->
          <a href="https://zerg.studio/" aria-current="page" class="navbar11_link w-nav-link">Home</a>
          <a href="https://zerg.studio/warriors" class="navbar11_link w-nav-link">Warriors</a>
          <a href="https://zerg.studio/lore" class="navbar11_link w-nav-link">Lore</a>
          <a href="https://points.zerg.studio" class="navbar11_link w-nav-link">$WAR</a>
          <a href="https://reveal.zerg.studio" target="_blank" class="navbar11_link w-nav-link">Reveal</a>
          <a href="https://awrd.gg/zergcustoms" target="_blank" class="navbar11_link w-nav-link">Customs</a>
          <div class="navbar11_menu-buttons">
            <a href="https://mint.zerg.studio" class="button is-secondary w-button">PURCHASE SIII</a>
          </div>
      </nav>
      </nav>
      <!-- <div class="navbar11_menu-button w-nav-button">
        <div class="menu-icon1">
          <div class="menu-icon1_line-top"></div>
          <div class="menu-icon1_line-middle">
            <div class="menu-icon_line-middle-inner"></div>
          </div>
          <div class="menu-icon1_line-bottom"></div>
        </div>
      </div> -->
      <!-- <div class="menu-button w-nav-button">
        <div class="w-icon-nav-menu"></div>
      </div> -->
    </div>
  </div> 
</template>
<script>
export default{
    name: 'NavBar',
    setup() {
        
    },
}
</script>
<style scoped>
@import url("https://use.typekit.net/yry4xnf.css");
.nav-pad{
  padding-top: 8px;
  padding-bottom: 8px;
}
.button{
  margin-top: 0rem;
}
</style>